import React from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import FileUpload from "../../../FileUpload";

export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [BatchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [BatchStartDate, setBatchStartDate] = React.useState(FetchData.meta.batch_start_date)
  const [BatchEndDate, setBatchEndDate] = React.useState(FetchData.meta.batch_end_date)
  const [BatchStatus, setBatchStatus] = React.useState(FetchData.meta.batch_status)

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = () => {
    setReload(true)
    const data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "batch_name": Title,
        "batch_start_date": BatchStartDate,
        "batch_end_date": BatchEndDate,
        "batch_status": BatchStatus,
        "students": []
      }
    }

    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_batch)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Batch Added Successfully")
          history.push(`${slugs.batch_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_batch, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Batch Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Batch Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch Start Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchStartDate} placeholder="Start Date"
                    onChange={(event) => { setBatchStartDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch End Date</td>
                <td className="col-6">
                  <input className="input-common"
                    value={BatchEndDate} placeholder="Name"
                    onChange={(event) => { setBatchEndDate(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Batch Status</td>
                <td className="col-6">
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Running"} />
                  <CustomButton setData={setBatchStatus} Data={BatchStatus} DataValue={"Ended"} />
                </td>
              </tr>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Batch
              </div>
            </div>
          </div>
        </div>
      </div>

      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div>
  )

}